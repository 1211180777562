<template>
  <div class="container tool-list-container mt-4">
    <b-tabs align="center" class="w-100">
      <b-tab active title="Training Videos">
        <div class="mb-5" style="text-align: -webkit-center" v-if="paginatedVideos.length > 0">
          <div class="tool-card" v-for="tool in paginatedVideos" :key="tool.id">
            <div v-if="tool.source != 'document'">
              <div style="align-items: start;" class="tool-card-content">
                <div class="tool-card-details">
                  <!-- $Can('support-edit') -->
                  <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.tempIndex + '. ' + tool.title }}</h3>
                  <p style="font-size:1em">{{ tool.description }}</p>
                </div>
              </div>
              <div class="tool-card-video">
                <iframe v-if="tool.source == 'youtube'" frameborder="0" height="500px" width="100%" :src="tool.video"></iframe>
                <video v-if="tool.source == 'upload'" id="video-preview" :key="tool.video" controls>
                  <source :src="tool.video" type="video/mp4" />
                </video>
              </div>
            </div>
            <div style="align-items: start;" class="tool-card-content" v-else>
              <div class="tool-card-details">
                <img class="tool-card-image" :src="tool.image" alt="Tool Image" />
                <b-button :href="tool.document" class="tool-card-button" block variant="warning">Download</b-button>
              </div>
              <div class="tool-card-details">
                <!-- $Can('support-edit') -->
                <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.tempIndex + '. ' + tool.title }}</h3>
                <p style="font-size:1em">{{ tool.description }}</p>
              </div>
            </div>
          </div>

          <paginate
            class="d-flex justify-content-center"
            :page-count="pageCountVideos"
            :click-handler="changePageVideos"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
          ></paginate>
        </div>
        <div class="w-100" v-else>
          <b-alert variant="info" show>
            <h4 class="alert-heading">
              File Not Found
            </h4>
            <div class="alert-body">
              <span>There are no files matching your request.</span>
            </div>
          </b-alert>
        </div>
      </b-tab>
      <b-tab title="Sample Videos">
        <div class="mb-5" style="text-align: -webkit-center" v-if="paginatedSample.length > 0">
          <div class="tool-card" v-for="tool in paginatedSample" :key="tool.id">
            <div v-if="tool.source != 'document'">
              <div class="tool-card-content">
                <div class="tool-card-details">
                  <!-- $Can('support-edit') -->
                  <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.tempIndex + '. ' + tool.title }}</h3>
                  <p style="font-size:1em">{{ tool.description }}</p>
                </div>
              </div>
              <div class="tool-card-video">
                <iframe v-if="tool.source == 'youtube'" frameborder="0" height="500px" width="100%" :src="tool.video"></iframe>
                <video v-if="tool.source == 'upload'" id="video-preview" :key="tool.video" style="height:500px; width:fit-content" controls>
                  <source :src="tool.video" type="video/mp4" />
                </video>
              </div>
            </div>
            <div class="tool-card-content" v-else>
              <div class="tool-card-details">
                <img class="tool-card-image" :src="tool.image" alt="Tool Image" />
                <b-button class="tool-card-button" block variant="primary" size="sm">Download</b-button>
              </div>
              <div class="tool-card-details">
                <!-- $Can('support-edit') -->
                <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.tempIndex + '. ' + tool.title }}</h3>
                <p style="font-size:1em">{{ tool.description }}</p>
              </div>
            </div>
          </div>
          <paginate
            class="d-flex justify-content-center"
            :page-count="pageCountSample"
            :click-handler="changePageSample"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
          ></paginate>
        </div>

        <div v-else>
          <b-alert variant="info" show>
            <h4 class="alert-heading">
              File Not Found
            </h4>
            <div class="alert-body">
              <span>There are no files matching your request.</span>
            </div>
          </b-alert>
        </div>
      </b-tab>
      <b-tab title="Training Manual">
        <div class="mb-5" style="text-align: -webkit-center" v-if="paginatedManual.length > 0">
          <div class="tool-card" v-for="tool in paginatedManual" :key="tool.id">
            <div v-if="tool.source != 'document'">
              <div style="align-items: start;" class="tool-card-content">
                <div class="tool-card-details">
                  <!-- $Can('support-edit') -->
                  <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.tempIndex + '. ' + tool.title }}</h3>
                  <p style="font-size:1em">{{ tool.description }}</p>
                </div>
              </div>
              <div class="tool-card-video">
                <!-- <iframe v-if="tool.source == 'youtube'" frameborder="0" height="500px" width="100%" :src="tool.video"></iframe> -->

                <iframe width="560" height="315" v-if="tool.source == 'youtube'" :src="tool.video" title="YouTube video player" frameborder="0" allow=" ; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                <video v-if="tool.source == 'upload'" id="video-preview" :key="tool.video" style="height;:500px" controls>
                  <source :src="tool.video" type="video/mp4" />
                </video>
              </div>
            </div>
            <div style="align-items: start;" class="tool-card-content" v-else>
              <div class="tool-card-details">
                <img class="tool-card-image" :src="tool.image" alt="Tool Image" />
                <b-button class="tool-card-button" :href="tool.document" block variant="warning">Download</b-button>
              </div>
              <div class="tool-card-details">
                <!-- $Can('support-edit') -->
                <h3 class="text-primary" @click="clickTitle(tool)">{{ tool.tempIndex + '. ' + tool.title }}</h3>
                <p style="font-size:1em">{{ tool.description }}</p>
              </div>
            </div>
          </div>
          <paginate
            class="d-flex justify-content-center"
            :page-count="pageCountManual"
            :click-handler="changePageManual"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-link-class="'page-link'"
            :prev-class="'page-item'"
            :prev-link-class="'page-link'"
            :next-class="'page-item'"
            :next-link-class="'page-link'"
          ></paginate>
        </div>

        <div v-else>
          <b-alert variant="info" show>
            <h4 class="alert-heading">
              File Not Found
            </h4>
            <div class="alert-body">
              <span>There are no files matching your request.</span>
            </div>
          </b-alert>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import axios from 'axios';
import axiosIns from '@/libs/axios';
import router from '@/router';
import 'animate.css';
import Paginate from 'vuejs-paginate';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    Paginate,
  },

  setup() {},

  data() {
    return {
      fetchUsers: [],

      filterOn: ['id', 'name', 'surname', 'email', 'role', 'status'],
      tableColumns: [
        { key: 'id', sortable: true, class: 'text-center' },
        { key: 'name', sortable: true, class: 'text-center' },
        { key: 'surname', sortable: true, class: 'text-center' },
        { key: 'email', sortable: true, class: 'text-center' },
        { key: 'role', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
        { key: 'actions', sortable: false },
      ],
      perPage: 3,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      tools: [],
      loading: false,
      manual: [],
      videos: [],
      sample: [],
      totalManualRows: 0,
      totalVideosRows: 0,
      totalSampleRows: 0,
      currentPageManual: 1,
      currentPageVideos: 1,
      currentPageSample: 1,
    };
  },

  methods: {
    getTools() {
      this.loading = true;

      axiosIns
        .get('resources')
        .then((response) => {
          this.tools = response.data;

          this.manual = [];
          var tempIndex = 1;

          if (response.data.hasOwnProperty('Training Manual')) {
            response.data['Training Manual'].forEach((element) => {
              element.tempIndex = tempIndex;
              this.manual.push(element);
              tempIndex++;
            });
            tempIndex = 1;
            this.videos = [];
          }
          if (response.data.hasOwnProperty('Training Videos')) {
            response.data['Training Videos'].forEach((element) => {
              element.tempIndex = tempIndex;
              this.videos.push(element);
              tempIndex++;
            });
            tempIndex = 1;

            this.sample = [];
          }

          if (response.data.hasOwnProperty('Sample Videos')) {
            response.data['Sample Videos'].forEach((element) => {
              element.tempIndex = tempIndex;
              this.sample.push(element);
              tempIndex++;
            });
          }

          this.totalRows = this.tools.length;
          this.totalManualRows = this.manual.length;
          this.totalVideosRows = this.videos.length;
          this.totalSampleRows = this.sample.length;

          this.loading = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    changePageManual(pageNumber) {
      this.currentPageManual = pageNumber;
    },
    changePageVideos(pageNumber) {
      this.currentPageVideos = pageNumber;
    },
    changePageSample(pageNumber) {
      this.currentPageSample = pageNumber;
    },

    clickTitle(tool) {
      if (this.$Can('resource_edit')) {
        router.push({ name: 'resources-edit', params: { id: tool.id } });
      }
    },
  },

  created() {
    this.getTools();
  },

  computed: {
    pageCountManual() {
      return Math.ceil(this.manual.length / this.perPage);
    },
    pageCountVideos() {
      return Math.ceil(this.videos.length / this.perPage);
    },
    pageCountSample() {
      return Math.ceil(this.sample.length / this.perPage);
    },

    paginatedTools() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      return this.tools.slice(startIndex, startIndex + this.perPage);
    },

    paginatedManual() {
      const startIndex = (this.currentPageManual - 1) * this.perPage;
      return this.manual.slice(startIndex, startIndex + this.perPage);
    },

    paginatedVideos() {
      const startIndex = (this.currentPageVideos - 1) * this.perPage;
      return this.videos.slice(startIndex, startIndex + this.perPage);
    },

    paginatedSample() {
      const startIndex = (this.currentPageSample - 1) * this.perPage;
      return this.sample.slice(startIndex, startIndex + this.perPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.tool-list-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 800px;
}

@media (min-width: 1000px) {
  .tool-list-container {
    justify-content: center;
  }

  #video-preview {
    height: 500px;
  }
  .tool-card {
    width: 1000px;
  }
}

@media (max-width: 1000px) {
  .tool-list-container {
    justify-content: flex-start;
  }
  #video-preview {
    width: 500px;
  }

  .tool-card {
    width: 500px;
  }
}

.tool-card {
  background-color: white;
  padding: 24px;
  margin-top: 40px;
  margin-bottom: 32px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  border: solid 1px #0b4666;
}

.tool-card-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.tool-card-image {
  width: 240px;
  height: 240px;
  margin-right: 24px;
  border-radius: 8px;
}

.tool-card-details {
  text-align: left;
}

.tool-card-details h3 {
  margin-top: 0;
  margin-bottom: 16px;
  cursor: pointer;
  font-weight: bold;
}

.tool-card-details p {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.5;
}

.tool-card-button {
  margin-top: 16px;
  width: 240px;
}

.tool-card-video {
  //   width: 100%;
  //   min-width: 300px;
  //   min-height: 500px;
  //   margin-bottom: 16px;
  //   overflow: hidden;
  //   border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 300px;
  min-height: 500px;
  margin-bottom: 16px;
  overflow: hidden;
  border-radius: 8px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
